<template>
  <div>
    <v-container>
      <v-alert
        :value="showAlert"
        :type="alert_type"
        @click:close="showAlert = false"
        dismissible
      >
        {{ message }}
      </v-alert>
      <div class="row">
        <div class="col-md-5 col-sm-5 col-xs-12">
          <v-carousel>
            <v-carousel-item
              v-for="image in $route.params.stockitem.product.images"
              :src="image.image"
              :key="image.id"
            >
            </v-carousel-item>
          </v-carousel>
        </div>
        <div class="col-md-7 col-sm-7 col-xs-12">
          <v-breadcrumbs class="pb-0" :items="breadcrums"></v-breadcrumbs>
          <div class="pl-6 containers">
            <p class="display-3 mb-0">
              {{ getFirstTwoWords($route.params.stockitem.product.title) }} {{ `${$route.params.stockitem.variation?$route.params.stockitem.variation.title:''}` }}
            </p>
            <v-card-actions class="pa-0 p-1 bg-light">
              <div>
                <p
                  class="headline font-weight-light pt-3"
                  v-if="$route.params.stockitem.discount"
                >
                  KShs {{ new Intl.NumberFormat().format(($route.params.stockitem.selling_price-($route.params.stockitem.discount.discount_type==='Percentage'?$route.params.stockitem.selling_price*($route.params.stockitem.discount.discount_amount/100):$route.params.stockitem.discount.discount_amount)))
                  }}<del style="" class="subtitle-1 font-weight-thin text-danger"
                    >&nbsp;KShs.{{
                      new Intl.NumberFormat().format(
                        $route.params.stockitem.selling_price
                      )
                    }}</del
                  >
                </p>
                <p class="headline font-weight-light pt-3 text-warning" v-else>
                  KShs.{{ new Intl.NumberFormat().format($route.params.stockitem.selling_price) }}
                </p>
              </div>
            
              <v-spacer></v-spacer>
              <v-rating
                v-model="highestreview"
                class=""
                background-color="warning lighten-3"
                color="warning"
                dense
              ></v-rating>
              <span class="body-2 font-weight-thin text-primary">
                {{ highestRatingCount }} Customer Reviews</span
              >
            </v-card-actions>
            <p class="subtitle-1 font-weight-thin">
              {{ $route.params.stockitem.product.title }}
            </p>
            <p class="subtitle-3 font-weight-thin">
              Shipping From:<span class="fa fa-shipping-fast text-warning">
                Official Store by
                <span class="badge badge-info bg-info">{{
                  $route.params.stockitem.location.name
                }}</span></span
              >
            </p>
            <div class="row">
              <div class="col-sm-6">
                <p class="title">
                  PACKAGING: 
                  {{ this.$route.params.stockitem.unit.title }}
                </p>
              </div>
            </div>
            <p class="title mt-2">QUANTITY</p>
            <v-text-field
              type="number"
              outlined
              style="width: 100px"
              v-model="quantity"
              :value="1"
              :step="1"
              min="1"
              dense
            ></v-text-field>
            <v-btn
              class="warning white--text"
              outlined
              tile
              dense
              @click="addToCart($route.params.stockitem)"
              ><v-icon>mdi-cart</v-icon> ADD TO CART</v-btn
            >
            <v-btn
              class="ml-4 pl-4"
              outlined
              tile
              @click="addFavorites($route.params.stockitem)"
              ><v-icon class="text-secondary">mdi-heart</v-icon>ADD TO FAVOURITES</v-btn
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xs-12 col-md-12">
          <v-tabs>
            <v-tab>Description</v-tab>
            <v-tab>REVIEWS</v-tab>
            <v-tab-item>
              <p class="pt-10 subtitle-1 font-weight-thin">
                {{ this.$route.params.stockitem.product.description }}
              </p>
            </v-tab-item>
            <v-tab-item>
              <v-list three-line="true" avatar="true" class="overflow-auto">
                <v-list-item-group v-model="item" color="primary">
                  <v-list-item v-for="(item, i) in reviews" :key="i" inactive="true">
                    <v-list-item-avatar class="bg-primary">
                      <v-text class="text-white">{{ item.user.username }}</v-text>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="item.user.username"></v-list-item-title
                      ><v-rating
                        v-model="item.rating"
                        class=""
                        background-color="warning lighten-3"
                        color="warning"
                        dense
                      ></v-rating>
                      <v-list-item-subtitle v-html="item.text"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-tab-item>
          </v-tabs>
          <div  class="card pa-0 pt-2" tile outlined>
            <p class="subtitle-1 font-weight-light pt-3 text-center">
              YOU MIGHT ALSO LIKE
            </p>
            <v-divider></v-divider>
            <div class="row">
              <div class="col-xl-4 col-lg-6 col-md-8 col-sm-12" v-for="item in relatedProducts" :key="item.id">
                <v-card>
                  <div class="card-title">
                    <span class="badge badge-pill bg-warning">{{ item.maincategory.name }}</span>
                  </div>
                  <v-img :src="`${item.images.length>0?item.images[0].image:require('@/assets/logo.png')}`" :aspect-ratio="16 / 9"></v-img>
                  <div class="card-title m-2 text-center">{{item.title }}</div>
                    <div class="row m-1 text-center">
                      <b-button class="btn btn-warning text-white" @click="addToCart(item)"><i class="uil uil-shopping-bag"></i>&nbsp;Add To Cart</b-button>
                    </div>
                  </v-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
//import { mapActions } from 'vuex';
//import cart from '@/state/modules/cart';
import axios from "@/Axiosconfig";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      rating: 4.5,
      breadcrums: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: this.$route.params.stockitem.product.maincategory.name,
          disabled: false,
          href: "/",
        },
        {
          text: this.getFirstTwoWords(this.$route.params.stockitem.product.title),
          disabled: true,
          href: "/",
        },
      ],
      item: 5,
      reviews: [
        {
          user: "Kelly",
          title: "Lorem ipsum dolor",
          rating: 4,
          subtitle:
            "<span class='text--primary'>Britta Holt</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        },
      ],
      product: null,
      stockitem: this.$route.params.stockitem,
      quantity: 1,
      relatedProducts: [],
      message: "Operation successful!",
      alert_type: "error",
      showAlert: false,
      highestreview: 1,
    };
  },

  computed: {
    highestRatingCount() {
      const highestRating = Math.max(...this.reviews.map((review) => review.rating));
      const highestRatingReviews = this.reviews.filter(
        (review) => review.rating === highestRating
      );
      return highestRatingReviews.length;
    },
  },
  mounted() {
    this.stockitem = this.$route.params.stockitem;
    this.updatearrays();
  },
  methods: {
    updatearrays() {
      Swal.fire({
        title: "Please Wait !",
        html: "Loading data...", // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .get(`products/${this.$route.params.stockitem.product.id}/`)
        .then((response) => {
          this.relatedProducts = response.data.related_products;
          let stockitem=this.$route.params.stockitem;
          axios
            .get(`reviews?sku=${stockitem.variation?stockitem.variation.sku:stockitem.sku}`)
            .then((response) => {
              if (response.data["results"].length > 0) {
                this.reviews = response.data["results"];
                const highestRating = Math.max(
                  ...this.reviews.map((review) => review.rating)
                );
                this.highestreview = this.reviews.filter(
                  (review) => review.rating === highestRating
                )[0].rating;
              }
            });
          Swal.close();
        })
        .catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
          }).then((e) => {
            Swal.close(e);
          });
        });
    },
    getFirstTwoWords(str) {
      // Split the sentence into an array of words
      const words = str.split(" ");
      // Take the first two words
      const firstTwoWords = words.slice(0, 3).join(" ");
      return firstTwoWords;
    },
    addToCart(item) {
      if (!sessionStorage.getItem("user")) {
        this.$router.push({ name: "login" });
        return;
      }
      var selling_price = item.selling_price;
      var tax_amount=0;
      var total_price=0;
      if(item.applicable_tax){
        tax_amount=selling_price*Number(item.applicable_tax.percentage);
        selling_price+=tax_amount;
      }
      if (item.variation != null) {
        if (item.discount) {
          selling_price = item.selling_price-(item.discount.discount_type=='Fixed'?item.discount.discount_amount:(item.discount.discount_amount/100)*item.selling_price);
          item.selling_price = selling_price;
        } else {
          selling_price = item.selling_price;
        }
      } else {
        if (item.discount) {
          selling_price = item.selling_price-(item.discount.discount_type=='Fixed'?item.discount.discount_amount:item.discount.discount_amount*item.selling_price);
          item.selling_price = selling_price;
        } else {
          selling_price = item.selling_price;
        }
      }
      total_price=selling_price*this.quantity;
      var cartItem = {
        user: JSON.parse(sessionStorage.user).user.id,
        product: item.product,
        stock_item: item.id,
        quantity: this.quantity,
        item_subtotal: selling_price, // cart subtotal
        tax: tax_amount, // tax
        item_total: total_price,
        selling_price: selling_price,
      };
      axios
        .post(`cart/?q=increase`, cartItem)
        .then((response) => {
          this.$store.dispatch("cart/addProductToCart", cartItem);
          this.message = response.data.message.toString();
          this.alert_type = response.data.icon;
          this.showAlert = true;
        })
        .catch((error) => {
          this.message = error.toString();
          this.alert_type = "error";
          this.showAlert = true;
        });
    },
    addFavorites(item) {
      if (!sessionStorage.getItem("user")) {
        this.$router.push({ name: "login" });
        return;
      }
      var selling_price = item.selling_price;
      var tax_amount=0;
      var total_price=0;
      if(item.applicable_tax){
        tax_amount=selling_price*Number(item.applicable_tax.percentage);
        selling_price+=tax_amount;
      }
      if (item.variation != null) {
        if (item.discount) {
          selling_price = item.selling_price-(item.discount.discount_type=='Fixed'?item.discount.discount_amount:item.discount.discount_amount*item.selling_price);
          item.selling_price = selling_price;
        } else {
          selling_price = item.selling_price;
        }
      } else {
        if (item.discount) {
          selling_price = item.selling_price-(item.discount.discount_type=='Fixed'?item.discount.discount_amount:item.discount.discount_amount*item.selling_price);
          item.selling_price = selling_price;
        } else {
          selling_price = item.selling_price;
        }
      }
      total_price=selling_price*this.quantity;
      var favItem = {
        product: item.product,
        item_subtotal: selling_price, // cart subtotal
        item_total: total_price,
      };
      this.$store.dispatch("favorites/addProductTofavorites", favItem);
      this.message = "Success!Item added to Favorites!";
      this.alert_type = "success";
      this.showAlert = true;
    },
  },
};
</script>
